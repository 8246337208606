import axios from "axios";

export const percepcionMaternidadApi = {
  create(data) {
    return axios.post('percepcion-maternidad', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(response => response.data)
  }
}

import {TipoNovedadConstants} from "@/modules/percepcionesAsignacionFamiliar/constants/tipoNovedad";

export const percepcionMaternidadForm = (user) => {
  return {
    tipo_percepcion: null,
    tipo_novedad: TipoNovedadConstants.ALTA,
    tipo_parentezco_empleado: null,
    dni_empleado: user ? user.persona.cuil.substring(2, 10) : null,
    cuil_empleado: user?.persona.cuil,
    nombre_empleado: user?.persona.nombre,
    email_empleado: user?.persona.email,
    telefono_empleado: user ? `${user.persona.caracteristica_celular}${user.persona.numero_celular}` : null,
    es_conyuge: false,
    acreditacion_conyuge: null,
    tipo_parentezco_progenitor: null,
    dni_progenitor: null,
    cuil_progenitor: null,
    nombre_progenitor: null,
    email_progenitor: null,
    telefono_progenitor: null,

    maternidad_tipo_licencia: null,
    maternidad_fecha_notificacion: null,
    maternidad_fecha_inicio: null,
    maternidad_fecha_fin: null,

    maternidad_down_fecha_fin_licencia: null,
    maternidad_down_cuil_hijo: null,
    maternidad_down_dni_hijo: null,
    maternidad_down_nombre_hijo: null,
    maternidad_down_tipo_partida: null,
    maternidad_down_numero_partida: null,
    maternidad_down_numero_tomo: null,
    maternidad_down_numero_folio: null,

    certificado_medico_tipo_matricula: null,
    certificado_medico_numero_matricula: null,
    certificado_medico_nombre_profesional: null,
    certificado_medico_lugar: null,
    certificado_medico_fecha: null,
    certificado_medico_fecha_certificado: null,
    certificado_medico_gestacion: null,
    certificado_medico_tipo_fecha_parto: null,
    certificado_medico_fecha_parto: null,
    certificado_medico_fecha_interrupcion_embarazo: null,
    certificado_medico_certificado: null
  }
}


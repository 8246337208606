<template>
  <div class="card">
    <h3 class="card-title">Crear DDJJ para percepción de asignaciones familiares Prenatal / Maternidad / Maternidad
      down</h3>
    <form @submit.prevent="save">
      <div class="cols2 my-2">
        <div class="form-group">
          <label class="form-label">Seleccione el tipo de percepción *</label>
          <select
            id="tipo_percepcion"
            class="form-control"
            v-model="percepcion.tipo_percepcion"
            :class="{'is-invalid': enviado && $v.percepcion.tipo_percepcion.$invalid}"
          >
            <option :value="null">Seleccione una opción</option>
            <option :value="TipoPercepcionConstants.PRENATAL">{{ TipoPercepcionConstants.PRENATAL }}</option>
            <option :value="TipoPercepcionConstants.MATERNIDAD">{{ TipoPercepcionConstants.MATERNIDAD }}</option>
            <option :value="TipoPercepcionConstants.MATERNIDAD_DOWN">{{ TipoPercepcionConstants.MATERNIDAD_DOWN }}
            </option>
          </select>
          <small class="form-message text-danger" v-if="enviado && $v.percepcion.tipo_percepcion.$invalid">
            Éste campo es obligatorio
          </small>
        </div>
      </div>
      <h4>Datos del/la trabajador/a</h4>
      <div class="cols2">
        <pd-input
          v-model="percepcion.dni_empleado"
          id="dni_empleado"
          label="DNI empleado *"
          placeholder="DNI empleado"
          readonly="readonly"
          :required="enviado && $v.percepcion.dni_empleado.$invalid"
        />
        <pd-input
          v-model="percepcion.cuil_empleado"
          id="cuil_empleado"
          label="CUIL empleado *"
          placeholder="CUIL empleado"
          readonly="readonly"
          :required="enviado && $v.percepcion.cuil_empleado.$invalid"
        />
        <pd-input
          v-model="percepcion.nombre_empleado"
          id="nombre_empleado"
          label="Nombre empleado *"
          placeholder="Nombre empleado"
          readonly="readonly"
          :required="enviado && $v.percepcion.nombre_empleado.$invalid"
        />
        <pd-input
          v-model="percepcion.email_empleado"
          id="email_empleado"
          label="Email empleado *"
          placeholder="Email empleado"
          readonly="readonly"
          :required="enviado && $v.percepcion.email_empleado.$invalid"
        />
        <pd-input
          v-model="percepcion.telefono_empleado"
          id="telefono_empleado"
          label="Teléfono empleado *"
          placeholder="Teléfono empleado"
          readonly="readonly"
          :required="enviado && $v.percepcion.telefono_empleado.$invalid"
        />

        <div class="form-group">
          <label class="form-label">Parentezco empleado *</label>
          <select
            id="parentezco_empleado"
            class="form-control"
            v-model="percepcion.tipo_parentezco_empleado"
            :class="{'is-invalid': enviado && $v.percepcion.tipo_parentezco_empleado.$invalid}"
          >
            <option :value="null">Seleccione parentezco</option>
            <option :value="TipoParentezcoConstants.MADRE">{{ TipoParentezcoConstants.MADRE }}</option>
            <option :value="TipoParentezcoConstants.PADRE">{{ TipoParentezcoConstants.PADRE }}</option>
          </select>
          <small class="form-message text-danger" v-if="enviado && $v.percepcion.tipo_parentezco_empleado.$invalid">
            Éste campo es obligatorio
          </small>
        </div>
      </div>

      <div class="cols2 my-2">
        <div class="form-group">
          <label class="form-label">¿Posee una relación de cónyuge, conviviente o conviviente previsional con el otro
            progenitor? (Deberá acreditarla) *</label>
          <select
            id="es_conyuge"
            class="form-control"
            v-model="percepcion.es_conyuge"
            :class="{'is-invalid': enviado && $v.percepcion.es_conyuge.$invalid}"
          >
            <option :value="null">Seleccione una opción</option>
            <option :value="true">SI</option>
            <option :value="false">NO</option>
          </select>
          <small class="form-message text-danger" v-if="enviado && $v.percepcion.es_conyuge.$invalid">
            Éste campo es obligatorio
          </small>
        </div>

        <pd-input
          v-if="percepcion.es_conyuge"
          type="file"
          id="acreditacion_conyuge"
          v-model="percepcion.acreditacion_conyuge"
          placeholder="Acreditación de conyuge"
          label="Acreditación de conyuge *"
          :required="enviado && $v.percepcion.acreditacion_conyuge.$invalid"
        />
      </div>

      <h4>Datos del otro progenitor</h4>
      <div class="cols2">
        <pd-input
          v-model="percepcion.dni_progenitor"
          id="dni_progenitor"
          label="DNI progenitor *"
          placeholder="DNI progenitor"
          :required="enviado && $v.percepcion.dni_progenitor.$invalid"
        />
        <pd-input
          v-model="percepcion.cuil_progenitor"
          id="cuil_progenitor"
          label="CUIL progenitor *"
          placeholder="CUIL progenitor"
          :required="enviado && $v.percepcion.cuil_progenitor.$invalid"
        />
        <pd-input
          v-model="percepcion.nombre_progenitor"
          id="nombre_progenitor"
          label="Nombre progenitor *"
          placeholder="Nombre progenitor"
          :required="enviado && $v.percepcion.nombre_progenitor.$invalid"
        />
        <pd-input
          v-model="percepcion.email_progenitor"
          id="email_progenitor"
          label="Email progenitor *"
          placeholder="Email progenitor"
          :required="enviado && $v.percepcion.email_progenitor.$invalid"
        />
        <pd-input
          v-model="percepcion.telefono_progenitor"
          id="telefono_progenitor"
          label="Teléfono progenitor *"
          placeholder="Teléfono progenitor"
          :required="enviado && $v.percepcion.telefono_progenitor.$invalid"
        />
        <div class="form-group">
          <label class="form-label">Parentezco progenitor *</label>
          <select
            id="parentezco_progenitor"
            class="form-control"
            v-model="percepcion.tipo_parentezco_progenitor"
            :class="{'is-invalid': enviado && $v.percepcion.tipo_parentezco_progenitor.$invalid}"
          >
            <option :value="null">Seleccione parentezco</option>
            <option :value="TipoParentezcoConstants.MADRE">{{ TipoParentezcoConstants.MADRE }}</option>
            <option :value="TipoParentezcoConstants.PADRE">{{ TipoParentezcoConstants.PADRE }}</option>
          </select>
          <small class="form-message text-danger" v-if="enviado && $v.percepcion.tipo_parentezco_progenitor.$invalid">
            Éste campo es obligatorio
          </small>
        </div>
      </div>

      <template v-if="percepcion.tipo_percepcion === TipoPercepcionConstants.PRENATAL">
        <h4>Prenatal</h4>
        <div class="cols2">
          <div class="form-group">
            <label class="form-label">Tipo de novedad *</label>
            <select
              id="tipo_novedad"
              class="form-control"
              :class="{'is-invalid': enviado && $v.percepcion.tipo_novedad.$invalid}"
              v-model="percepcion.tipo_novedad"
            >
              <option :value="null">Seleccione una opción</option>
              <option :value="TipoNovedadConstants.ALTA">{{ TipoNovedadConstants.ALTA }}</option>
              <option :value="TipoNovedadConstants.BAJA">{{ TipoNovedadConstants.BAJA }}</option>
              <option :value="TipoNovedadConstants.MODIFICACION">{{ TipoNovedadConstants.MODIFICACION }}</option>
            </select>
            <small class="form-message text-danger" v-if="enviado && $v.percepcion.tipo_novedad.$invalid">
              Éste campo es obligatorio
            </small>
          </div>
        </div>
      </template>
      <template v-if="percepcion.tipo_percepcion === TipoPercepcionConstants.MATERNIDAD">
        <h4>Licencia por maternidad</h4>
        <div class="cols2">
          <div class="form-group">
            <label class="form-label">Tipo de novedad *</label>
            <select
              id="tipo_novedad"
              class="form-control"
              v-model="percepcion.tipo_novedad"
              :class="{'is-invalid': enviado && $v.percepcion.tipo_novedad.$invalid}"
            >
              <option :value="null">Seleccione una opción</option>
              <option :value="TipoNovedadConstants.ALTA">{{ TipoNovedadConstants.ALTA }}</option>
              <option :value="TipoNovedadConstants.BAJA">{{ TipoNovedadConstants.BAJA }}</option>
            </select>
            <small class="form-message text-danger" v-if="enviado && $v.percepcion.tipo_novedad.$invalid">
              Éste campo es obligatorio
            </small>
          </div>
          <div class="form-group">
            <label class="form-label">Tipo de licencia *</label>
            <select
              id="tipo_novedad"
              class="form-control"
              v-model="percepcion.maternidad_tipo_licencia"
              :class="{'is-invalid': enviado && $v.percepcion.maternidad_tipo_licencia.$invalid}"
            >
              <option :value="null">Seleccione una opción</option>
              <option :value="TipoLicenciaMaternidadConstants.LIC_45_45">45 días Pre Parto y 45 días Post Parto</option>
              <option :value="TipoLicenciaMaternidadConstants.LIC_30_60">30 días Pre Parto y 60 días Post Parto</option>
            </select>
            <small class="form-message text-danger" v-if="enviado && $v.percepcion.maternidad_tipo_licencia.$invalid">
              Éste campo es obligatorio
            </small>
          </div>
          <pd-input
            v-model="percepcion.maternidad_fecha_notificacion"
            id="fecha_notificacion"
            label="Fecha de notificacion al empleador de la declaración de opción de licencia *"
            type="date"
            :required="enviado && $v.percepcion.maternidad_fecha_notificacion.$invalid"
          />
        </div>
        <div class="cols2">
          <pd-input
            v-model="percepcion.maternidad_fecha_inicio"
            id="fecha_inicio"
            label="Fecha de inicio de licencia por maternidad *"
            type="date"
            :required="enviado && $v.percepcion.maternidad_fecha_inicio.$invalid"
          />
          <pd-input
            v-model="percepcion.maternidad_fecha_fin"
            id="fecha_fin"
            label="Fecha de fin de licencia por maternidad *"
            type="date"
            :required="enviado && $v.percepcion.maternidad_fecha_fin.$invalid"
          />

        </div>
      </template>
      <template v-if="
        percepcion.tipo_percepcion === TipoPercepcionConstants.MATERNIDAD ||
        percepcion.tipo_percepcion === TipoPercepcionConstants.PRENATAL
       "
      >
        <h4>Datos de certificado médico</h4>
        <div class="cols2">
          <pd-input
            v-model="percepcion.certificado_medico_fecha_certificado"
            id="certificado_medico_fecha_certificado"
            label="Fecha del certificado médico"
            type="date"
          />
          <pd-input
            v-model="percepcion.certificado_medico_gestacion"
            id="gestacion"
            label="Gestación en semanas"
            type="number"
          />
          <div class="form-group">
            <label class="form-label">Tipo de fecha de parto</label>
            <select
              id="tipo_novedad"
              class="form-control"
              v-model="percepcion.certificado_medico_tipo_fecha_parto"
            >
              <option :value="null">Seleccione una opción</option>
              <option :value="TipoFechaPartoConstants.PROBABLE">{{ TipoFechaPartoConstants.PROBABLE }}</option>
              <option :value="TipoFechaPartoConstants.REAL">{{ TipoFechaPartoConstants.REAL }}</option>
            </select>
          </div>
          <pd-input
            v-model="percepcion.certificado_medico_fecha_parto"
            id="certificado_medico_fecha_parto"
            label="Fecha de parto"
            type="date"
          />

          <pd-input
            v-model="percepcion.certificado_medico_fecha_interrupcion_embarazo"
            id="certificado_medico_fecha_interrupcion_embarazo"
            label="Fecha de interrupcion de parto"
            type="date"
          />

        </div>
        <div>
          <pd-input
            v-model="percepcion.certificado_medico_certificado"
            id="certificado_medico_certificado"
            label="Adjunte certificado *"
            type="file"
            :required="enviado && $v.percepcion.certificado_medico_certificado.$invalid"
          />
        </div>
      </template>


      <template v-if="percepcion.tipo_percepcion === TipoPercepcionConstants.MATERNIDAD_DOWN">
        <h4>Maternidad down</h4>
        <div class="cols2">
          <div class="form-group">
            <label class="form-label">Tipo de novedad *</label>
            <select
              id="tipo_novedad"
              class="form-control"
              :class="{'is-invalid': enviado && $v.percepcion.tipo_novedad.$invalid}"
              v-model="percepcion.tipo_novedad"
            >
              <option :value="null">Seleccione una opción</option>
              <option :value="TipoNovedadConstants.ALTA">{{ TipoNovedadConstants.ALTA }}</option>
              <option :value="TipoNovedadConstants.BAJA">{{ TipoNovedadConstants.BAJA }}</option>
            </select>
            <small class="form-message text-danger" v-if="enviado && $v.percepcion.tipo_novedad.$invalid">
              Éste campo es obligatorio
            </small>
          </div>

          <pd-input
            v-model="percepcion.maternidad_down_fecha_fin_licencia"
            id="maternidad_down_fecha_fin_licencia"
            label="Fecha de fin de licencia por maternidad *"
            type="date"
            placeholder="date"
            :required="enviado && $v.percepcion.maternidad_down_fecha_fin_licencia.$invalid"
          />

          <pd-input
            v-model="percepcion.maternidad_down_cuil_hijo"
            id="maternidad_down_cuil_hijo"
            label="Cuil hijo *"
            placeholder="Cuil hijo"
            :required="enviado && $v.percepcion.maternidad_down_cuil_hijo.$invalid"
          />

          <pd-input
            v-model="percepcion.maternidad_down_dni_hijo"
            id="maternidad_dni_cuil_hijo"
            label="DNI hijo *"
            placeholder="DNI hijo"
            :required="enviado && $v.percepcion.maternidad_down_dni_hijo.$invalid"
          />

          <pd-input
            v-model="percepcion.maternidad_down_nombre_hijo"
            id="maternidad_down_nombre_hijo"
            label="Apellido y nombre completo de hijo *"
            placeholder="Apellido y nombre completo de hijo"
            :required="enviado && $v.percepcion.maternidad_down_nombre_hijo.$invalid"
          />

          <div class="form-group">
            <label class="form-label">Tipo de partida *</label>
            <select
              id="tipo_partida"
              class="form-control"
              :class="{'is-invalid': enviado && $v.percepcion.maternidad_down_tipo_partida.$invalid}"
              v-model="percepcion.maternidad_down_tipo_partida"
            >
              <option :value="null">Seleccione una opción</option>
              <option :value="TipoPartidaConstants.NACIMIENTO">{{ TipoPartidaConstants.NACIMIENTO }}</option>
              <option :value="TipoPartidaConstants.DEFUNCION">{{ TipoPartidaConstants.DEFUNCION }}</option>
            </select>
            <small class="form-message text-danger"
                   v-if="enviado && $v.percepcion.maternidad_down_tipo_partida.$invalid">
              Éste campo es obligatorio
            </small>
          </div>

          <pd-input
            v-model="percepcion.maternidad_down_numero_partida"
            id="maternidad_down_numero_partida"
            label="N° de Acta/Partida/Certificado *"
            placeholder="N° de Acta/Partida/Certificado"
            :required="enviado && $v.percepcion.maternidad_down_numero_partida.$invalid"
          />

          <pd-input
            v-model="percepcion.maternidad_down_numero_tomo"
            id="maternidad_down_numero_tomo"
            label="N° de tomo *"
            placeholder="N° de tomo"
            :required="enviado && $v.percepcion.maternidad_down_numero_tomo.$invalid"
          />

          <pd-input
            v-model="percepcion.maternidad_down_numero_folio"
            id="maternidad_down_numero_folio"
            label="N° de folio *"
            placeholder="N° de folio"
            :required="enviado && $v.percepcion.maternidad_down_numero_folio.$invalid"
          />

        </div>
        <h4>Datos de certificado médico</h4>
        <div>
          <pd-input
            v-model="percepcion.certificado_medico_certificado"
            id="certificado_medico_certificado"
            label="Adjunte certificado *"
            type="file"
            :required="enviado && $v.percepcion.certificado_medico_certificado.$invalid"
          />
        </div>
      </template>

      <div class="mt-2">
        <button class="btn btn-right btn-primary">Guardar</button>
      </div>
    </form>
  </div>
</template>
<script>
import {
  percepcionMaternidadForm
} from "@/modules/percepcionesAsignacionFamiliar/services/percepcionMaternidadForm";
import {mapState} from "vuex";
import Suggest from "@/components/elements/Suggest.vue";
import {TipoParentezcoConstants} from "@/modules/percepcionesAsignacionFamiliar/constants/tipoParentezco";
import {TipoPercepcionConstants} from "@/modules/percepcionesAsignacionFamiliar/constants/tipoPercepcion";
import {TipoNovedadConstants} from "@/modules/percepcionesAsignacionFamiliar/constants/tipoNovedad";
import {required, requiredIf} from "vuelidate/lib/validators";
import {TipoLicenciaMaternidadConstants} from "../constants/tipoLicenciaMaternidad";
import {TipoFechaPartoConstants} from "../constants/tipoFechaParto";
import {TipoPartidaConstants} from "@/modules/percepcionesAsignacionFamiliar/constants/tipoPartida";
import Swal from "sweetalert2";
import {percepcionMaternidadApi} from "@/modules/percepcionesAsignacionFamiliar/services/percepcionMaternidadApi";

export default {
  name: 'CrearPercepcion',
  components: {Suggest},
  data: () => {
    return {
      percepcion: percepcionMaternidadForm(null),
      enviado: false
    }
  },
  validations: {
    percepcion: {
      tipo_percepcion: {required},
      tipo_novedad: {required},
      tipo_parentezco_empleado: {required},
      dni_empleado: {required},
      cuil_empleado: {required},
      nombre_empleado: {required},
      email_empleado: {required},
      telefono_empleado: {required},
      es_conyuge: {required},
      acreditacion_conyuge: {
        required: requiredIf(function (percepcion) {
          return percepcion.es_conyuge
        })
      },
      tipo_parentezco_progenitor: {required},
      dni_progenitor: {required},
      cuil_progenitor: {required},
      nombre_progenitor: {required},
      email_progenitor: {required},
      telefono_progenitor: {required},
      maternidad_tipo_licencia: {
        required: requiredIf(function (percepcion) {
          return percepcion.tipo_percepcion === TipoPercepcionConstants.MATERNIDAD
        })
      },
      maternidad_fecha_notificacion: {
        required: requiredIf(function (percepcion) {
          return percepcion.tipo_percepcion === TipoPercepcionConstants.MATERNIDAD
        })
      },
      maternidad_fecha_inicio: {
        required: requiredIf(function (percepcion) {
          return percepcion.tipo_percepcion === TipoPercepcionConstants.MATERNIDAD
        })
      },
      maternidad_fecha_fin: {
        required: requiredIf(function (percepcion) {
          return percepcion.tipo_percepcion === TipoPercepcionConstants.MATERNIDAD
        })
      },
      certificado_medico_certificado: {
        required: requiredIf(function (percepcion) {
          return percepcion.tipo_percepcion === TipoPercepcionConstants.MATERNIDAD ||
            percepcion.tipo_percepcion === TipoPercepcionConstants.PRENATAL
        })
      },

      maternidad_down_fecha_fin_licencia: {
        required: requiredIf(function (percepcion) {
          return percepcion.tipo_percepcion === TipoPercepcionConstants.MATERNIDAD_DOWN
        })
      },
      maternidad_down_cuil_hijo: {
        required: requiredIf(function (percepcion) {
          return percepcion.tipo_percepcion === TipoPercepcionConstants.MATERNIDAD_DOWN
        })
      },
      maternidad_down_dni_hijo: {
        required: requiredIf(function (percepcion) {
          return percepcion.tipo_percepcion === TipoPercepcionConstants.MATERNIDAD_DOWN
        })
      },
      maternidad_down_nombre_hijo: {
        required: requiredIf(function (percepcion) {
          return percepcion.tipo_percepcion === TipoPercepcionConstants.MATERNIDAD_DOWN
        })
      },
      maternidad_down_tipo_partida: {
        required: requiredIf(function (percepcion) {
          return percepcion.tipo_percepcion === TipoPercepcionConstants.MATERNIDAD_DOWN
        })
      },
      maternidad_down_numero_partida: {
        required: requiredIf(function (percepcion) {
          return percepcion.tipo_percepcion === TipoPercepcionConstants.MATERNIDAD_DOWN
        })
      },
      maternidad_down_numero_tomo: {
        required: requiredIf(function (percepcion) {
          return percepcion.tipo_percepcion === TipoPercepcionConstants.MATERNIDAD_DOWN
        })
      },
      maternidad_down_numero_folio: {
        required: requiredIf(function (percepcion) {
          return percepcion.tipo_percepcion === TipoPercepcionConstants.MATERNIDAD_DOWN
        })
      },
    }
  },
  computed: {
    TipoPartidaConstants() {
      return TipoPartidaConstants
    },
    TipoFechaPartoConstants() {
      return TipoFechaPartoConstants
    },
    TipoLicenciaMaternidadConstants() {
      return TipoLicenciaMaternidadConstants
    },
    TipoNovedadConstants() {
      return TipoNovedadConstants
    },
    TipoPercepcionConstants() {
      return TipoPercepcionConstants
    },
    TipoParentezcoConstants() {
      return TipoParentezcoConstants
    },
    ...mapState(["user"]),
  },
  mounted() {
    this.percepcion = percepcionMaternidadForm(this.user)
  },
  methods: {
    save() {
      this.enviado = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }

      const formData = new FormData();
      formData.append('tipo_percepcion', this.percepcion.tipo_percepcion)
      formData.append('tipo_novedad', this.percepcion.tipo_novedad)
      formData.append('tipo_parentezco_empleado', this.percepcion.tipo_parentezco_empleado)
      formData.append('dni_empleado', this.percepcion.dni_empleado)
      formData.append('cuil_empleado', this.percepcion.cuil_empleado)
      formData.append('nombre_empleado', this.percepcion.nombre_empleado)
      formData.append('email_empleado', this.percepcion.email_empleado)
      formData.append('telefono_empleado', this.percepcion.telefono_empleado)
      formData.append('es_conyuge', this.percepcion.es_conyuge)
      formData.append('acreditacion_conyuge', document.querySelector("input#acreditacion_conyuge")?.files[0])
      formData.append('tipo_parentezco_progenitor', this.percepcion.tipo_parentezco_progenitor)
      formData.append('dni_progenitor', this.percepcion.dni_progenitor)
      formData.append('cuil_progenitor', this.percepcion.cuil_progenitor)
      formData.append('nombre_progenitor', this.percepcion.nombre_progenitor)
      formData.append('email_progenitor', this.percepcion.email_progenitor)
      formData.append('telefono_progenitor', this.percepcion.telefono_progenitor)
      formData.append('maternidad_tipo_licencia', this.percepcion.maternidad_tipo_licencia)
      formData.append('maternidad_fecha_notificacion', this.percepcion.maternidad_fecha_notificacion)
      formData.append('maternidad_fecha_inicio', this.percepcion.maternidad_fecha_inicio)
      formData.append('maternidad_fecha_fin', this.percepcion.maternidad_fecha_fin)
      formData.append('maternidad_down_fecha_fin_licencia', this.percepcion.maternidad_down_fecha_fin_licencia)
      formData.append('maternidad_down_cuil_hijo', this.percepcion.maternidad_down_cuil_hijo)
      formData.append('maternidad_down_dni_hijo', this.percepcion.maternidad_down_dni_hijo)
      formData.append('maternidad_down_nombre_hijo', this.percepcion.maternidad_down_nombre_hijo)
      formData.append('maternidad_down_tipo_partida', this.percepcion.maternidad_down_tipo_partida)
      formData.append('maternidad_down_numero_partida', this.percepcion.maternidad_down_numero_partida)
      formData.append('maternidad_down_numero_tomo', this.percepcion.maternidad_down_numero_tomo)
      formData.append('maternidad_down_numero_folio', this.percepcion.maternidad_down_numero_folio)
      formData.append('certificado_medico_tipo_matricula', this.percepcion.certificado_medico_tipo_matricula)
      formData.append('certificado_medico_numero_matricula', this.percepcion.certificado_medico_numero_matricula)
      formData.append('certificado_medico_nombre_profesional', this.percepcion.certificado_medico_nombre_profesional)
      formData.append('certificado_medico_lugar', this.percepcion.certificado_medico_lugar)
      formData.append('certificado_medico_fecha', this.percepcion.certificado_medico_fecha)
      formData.append('certificado_medico_fecha_certificado', this.percepcion.certificado_medico_fecha_certificado)
      formData.append('certificado_medico_gestacion', this.percepcion.certificado_medico_gestacion)
      formData.append('certificado_medico_tipo_fecha_parto', this.percepcion.certificado_medico_tipo_fecha_parto)
      formData.append('certificado_medico_fecha_parto', this.percepcion.certificado_medico_fecha_parto)
      formData.append('certificado_medico_fecha_interrupcion_embarazo', this.percepcion.certificado_medico_fecha_interrupcion_embarazo)
      formData.append('certificado_medico_certificado', document.querySelector("input#certificado_medico_certificado")?.files[0])
      percepcionMaternidadApi.create(formData).then((response) => {
        if (response.success) {
          Swal.fire("Éxito", "Se guardó correcamente", "success");
          this.$router.push({name: "ListarPercepciones"});
        } else {
          Swal.fire("Error", "No se pudo guardar", "error");
        }
      })
        .catch((error) => {
          Swal.fire(
            "Error",
            `<p>Error de servidor</p><p>${error}</p>`,
            "error"
          );
        });
    }
  }
}
</script>

<style scoped>

</style>
